import {format} from 'date-fns';

export const eventFormDataToRegistrant = formData => {
    return {
        ...formData,
        fullName: formData.fullName?.trim(),
        email: formData.email?.trim(),
        phoneNumber: formData.phoneNumber?.trim(),
        countryName: formData.countryName?.trim(),
        dinner: Boolean( parseInt( formData.dinner ) ),
        partnerMeeting: Boolean( parseInt( formData.partnerMeeting ) ),
        entryVisaLetter: Boolean( parseInt( formData.entryVisaLetter ) )
    };
}

export function getStatusStyle ( status ) {
    switch ( status ) {
        case 'Confirmed':
            return 'bg-blue';
        case 'Waitlisted':
            return 'bg-grey-dark';
        case 'Cancelled':
            return 'bg-grey-light';
        default:
            return null;
    }
}

export function getCountryNameFromCode ( countryList, code ) {
    const country = countryList.find( item => item.Value === code );
    return country ? country.Name : 'Not provided';
}

export const formatEventDate = ( date ) => date ? format( new Date( date ), 'yyyy-MM-dd' ) : null;
export const formatYesNo = bool => {
    if ( bool === undefined || bool === null ) {
        return 'Unknown';
    }

    let value = bool;
    if ( typeof value === 'string' ) {
        value = parseInt( value );
    }

    return value ? 'Yes' : 'No';
}
