import {isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {useMemo} from 'react';
import {useFormik} from 'formik';
import {validateEmail, validateFullName} from '@ultradent/utilities/Strings';
import {selector, useRecoilValueLoadable} from 'recoil';
import GeneralDataService from '@/providers/generalData';

export const countryListSelector = selector( {
    key: 'countryListSelector',
    get: async () => {
        const response = await GeneralDataService.getCountryCodes();
        return response.data;
    }
} );

function getOptionalRadioValue ( value ) {
    if ( value === true || value === '1' ) {
        return '1';
    }
    else if ( value === false || value === '0' ) {
        return '0';
    }
    else {
        return '';
    }
}

const createRegistrantModel = userDefaults => data => {
    return {
        id: data?.id || '',
        companyName: data?.companyName || userDefaults?.companyName || '',
        fullName: data?.fullName || userDefaults?.fullName || '',
        email: data?.email || userDefaults?.email || '',
        phoneNumber: data?.phoneNumber || userDefaults?.phoneNumber || '',
        country: data?.country || userDefaults?.country || '',
        irmFullName: data?.irmFullName || userDefaults?.irmFullName || '',
        dietaryRequirements: data?.dietaryRequirements || [],
        dinner: getOptionalRadioValue( data?.dinner ),
        partnerMeeting: getOptionalRadioValue( data?.partnerMeeting ),
        entryVisaLetter: data?.entryVisaLetter ? '1' : '0', // no by default
        status: data?.status || 'Call Back',
        notes: data?.notes || ''
    }
}

export const useRegistrantForm = ( {
                                       eventId,
                                       eventName,
                                       onSubmit,
                                       onCancel,
                                       registrant,
                                       prepopulate,
                                       userInfo = {}
                                   } ) => {
    const countryListRequest = useRecoilValueLoadable( countryListSelector );

    function formatDateFieldValue ( date ) {
        if ( isEmptyOrNil( date ) ) {
            return '';
        }

        const d = new Date( date );
        return d.toISOString().split( 'T' )[0];
    }

    // note - business only want to populate full name, email, phone fields for the first registrant entered,
    //  all additional entries should only pre-populate company name
    //  If userInfo is supplied then pre-populate these fields
    const createRegistrant = useMemo( () => createRegistrantModel( {
        irmFullName: userInfo.irmFullName,
        country: userInfo.crmAccountCountry,
        companyName: userInfo.crmAccountName,
        fullName: prepopulate && userInfo.fullName,
        email: prepopulate && userInfo.email,
        phoneNumber: prepopulate && userInfo.phone
    } ), [registrant, prepopulate] );

    const formik = useFormik( {
        enableReinitialize: true,
        initialValues: {
            eventId,
            eventName: eventName || '',
            ...createRegistrant( registrant )
        },
        validate: ( values ) => {
            const errors = {};

            if ( isEmptyOrNil( values.companyName ) ) {
                errors.companyName = 'Company name is required';
            }
            if ( !validateFullName( values.fullName ) ) {
                errors.fullName = 'Please enter a first and last name';
            }
            if ( isEmptyOrNil( values.fullName ) ) {
                errors.fullName = 'Full name is required';
            }
            if ( !validateEmail( values.email ) ) {
                errors.email = 'Please enter a valid email address';
            }
            if ( isEmptyOrNil( values.email ) ) {
                errors.email = 'Email is required';
            }
            if ( isEmptyOrNil( values.phoneNumber ) ) {
                errors.phoneNumber = 'Phone number is required';
            }
            if ( isEmptyOrNil( values.dinner ) ) {
                errors.dinner = 'Please let us know if you will be attending the dinner on 23rd of March?';
            }
            if ( isEmptyOrNil( values.partnerMeeting ) ) {
                errors.partnerMeeting =
                    'Please let us know if you will be attending the Partner Meeting on 24th of March?';
            }
            if ( isEmptyOrNil( values.dietaryRequirements ) ) {
                errors.dietaryRequirements = 'Dietary requirements is required';
            }

            return errors;
        },
        onSubmit: ( registrant, formikHelpers ) => {
            if ( typeof onSubmit === 'function' ) {
                onSubmit( registrant );
                formikHelpers.resetForm();
            }
        }
    } );

    return {
        formik,
        countryList: countryListRequest.state === 'hasValue' ? countryListRequest.contents : [],
        formatDateFieldValue,
        onCancel: () => {
            if ( typeof onCancel === 'function' ) {
                onCancel();
            }
        }
    };
}
