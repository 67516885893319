import React from 'react';
import {FormikProvider} from 'formik';
import {EventResponseStatus} from '@/constants/appConstants';
import {
    Button,
    Checkbox,
    FormFeedback,
    InputFeedback,
    Radio,
    SelectField,
    Show,
    SkeletonElement,
    Textarea,
    TextField
} from '@ultradent/components';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {getStatusStyle} from '../transformers';

export const RegistrantForm = ( {formManager, isEventAdmin, isProcessing, postErrors} ) => {
    const {
        onCancel,
        formik,
        countryList
    } = formManager;

    return (
        <FormikProvider value={formik}>
            <FormFeedback className="bg-fail text-white mb-4" {...postErrors}/>
            <form onSubmit={formik.handleSubmit} noValidate className="max-w-xl">
                <div className="p-4 mb-6 md:p-8 bg-moon-grey">
                    <FormFeedback className="bg-fail text-white mb-4" {...formik} />
                    <h3 className="subheading-base mb-4">Request to register for the event</h3>
                    <fieldset className="mb-8 grid grid-cols-1 gap-4 lg:grid-cols-2">
                        <input hidden id="id" onChange={formik.handleChange} value={formik.values.id}/>
                        <input hidden id="eventId" onChange={formik.handleChange} value={formik.values.eventId}/>
                        <input hidden id="eventName" onChange={formik.handleChange} value={formik.values.eventName}/>
                        <input hidden id="irmFullName" onChange={formik.handleChange}
                               value={formik.values.irmFullName}/>

                        <TextField id="fullName"
                                   label="Full Name"
                                   labelAppend="(Required)"
                                   className="row-start-1"
                                   onChange={formik.handleChange}
                                   value={formik.values.fullName}
                                   error={formik.touched.fullName && formik.errors.fullName}/>
                        <TextField id="email"
                                   label="Email"
                                   labelAppend="(Required)"
                                   className="row-start-2"
                                   onChange={formik.handleChange}
                                   value={formik.values.email}
                                   error={formik.touched.email && formik.errors.email}/>
                        <TextField id="phoneNumber"
                                   label="Phone Number with Country Code"
                                   labelAppend="(Required)"
                                   className="row-start-3"
                                   onChange={formik.handleChange}
                                   value={formik.values.phoneNumber}
                                   error={formik.touched.phoneNumber && formik.errors.phoneNumber}/>
                        <TextField id="companyName"
                                   label="Company Name"
                                   labelAppend="(Required)"
                                   className="row-start-4"
                                   onChange={formik.handleChange}
                                   value={formik.values.companyName}
                                   error={formik.touched.companyName && formik.errors.companyName}/>
                        <SelectField id="country"
                                     label="Country"
                                     className="row-start-5"
                                     onChange={formik.handleChange}
                                     value={formik.values.country}
                                     error={formik.touched.country && formik.errors.country}>
                            <option value="">Select Country</option>
                            {countryList.map( country =>
                                <option key={country.Value} value={country.Value}>{country.Name}</option>
                            )}
                        </SelectField>
                    </fieldset>
                    <fieldset>
                        <label
                            className="mb-2">Will you attend the dinner on 23rd of March? (Required)</label>
                        <div className="flex flex-col">
                            <Radio
                                name="dinner"
                                value="1"
                                label="Yes"
                            />
                            <Radio
                                name="dinner"
                                value="0"
                                label="No"
                            />
                             <InputFeedback
                                 error={formik.touched.dinner && formik.errors.dinner}/>
                        </div>
                    </fieldset>
                    <fieldset>
                        <label
                            className="mb-2">Will you attend the Partner Meeting on 24th of March? (Required)</label>
                        <div className="flex flex-col">
                            <Radio
                                name="partnerMeeting"
                                value="1"
                                label="Yes"
                            />
                            <Radio
                                name="partnerMeeting"
                                value="0"
                                label="No"
                            />
                            <InputFeedback
                                error={formik.touched.partnerMeeting && formik.errors.partnerMeeting}/>
                        </div>
                    </fieldset>
                    <fieldset>
                        <label className="mb-2">Dietary Requirements (Required)</label>
                        <div className="flex flex-col">
                            <Checkbox name="dietaryRequirements" value="Vegan" label="Vegan"/>
                            <Checkbox name="dietaryRequirements" value="Vegetarian" label="Vegetarian"/>
                            <Checkbox name="dietaryRequirements" value="Kosher" label="Kosher"/>
                            <Checkbox name="dietaryRequirements" value="Halal" label="Halal"/>
                            <Checkbox name="dietaryRequirements" value="Gluten-Free" label="Gluten-Free"/>
                            <Checkbox name="dietaryRequirements" value="Other" label="Other"/>
                            <Checkbox name="dietaryRequirements" value="None" label="None"/>
                            <InputFeedback
                                error={formik.touched.dietaryRequirements && formik.errors.dietaryRequirements}/>
                        </div>

                    </fieldset>
                    <fieldset>
                        <p>If you require an invitation letter for visa processing purposes, please contact Maike Brewing (<a
                            href={'mailto:Maike.Brewing@ultradent.com'}>Maike.Brewing@ultradent.com</a>)</p>
                    </fieldset>

                    <Show when={isEventAdmin}>
                        <h3 className="subheading-base mb-4">Additional Information</h3>
                        <fieldset>
                            <SelectField
                                label="Status"
                                className="md:w-48"
                                inputClassName={getStatusStyle( formik.values.status )}
                                id="status"
                                value={formik.values.status}
                                onChange={formik.handleChange}>
                                {EventResponseStatus.map( status =>
                                    <option key={status} value={status}>{status}</option>
                                )}
                            </SelectField>
                        </fieldset>
                        <fieldset>
                            <Textarea
                                label="Notes"
                                id="notes"
                                onChange={formik.handleChange}
                                value={formik.values.notes}
                                error={formik.touched.notes && formik.errors.notes}
                            />
                        </fieldset>
                    </Show>
                </div>
                <FormFeedback className="bg-fail text-white mb-4" {...postErrors}/>
                <div className="flex">
                    <Button onClick={onCancel} type="button" tertiary small className="mr-2">Cancel</Button>
                    <Button processing={isProcessing} type="submit" small>
                        {notEmptyOrNil( formik.values.id )
                            ? 'Save'
                            : 'Next'
                        }
                    </Button>
                </div>
            </form>
        </FormikProvider>
    )
}

RegistrantForm.Skeleton = () =>
    <div className="max-w-xl">
        <SkeletonElement className="mb-10 h-8 w-1/2"/>
        <div className="p-4 mb-6 md:p-8 bg-moon-grey">
            <SkeletonElement type="title" className="mb-6"/>
            <SkeletonElement className="mb-1 h-4 w-32"/>
            <SkeletonElement className="mb-4 h-8 bg-white md:w-2/3"/>
            <SkeletonElement className="mb-1 h-4 w-32"/>
            <SkeletonElement className="mb-4 h-8 bg-white md:w-2/3"/>
            <SkeletonElement className="mb-1 h-4 w-32"/>
            <SkeletonElement className="mb-4 h-8 bg-white md:w-2/3"/>
            <SkeletonElement className="mb-1 h-4 w-32"/>
            <SkeletonElement className="mb-4 h-8 bg-white md:w-2/3"/>
            <SkeletonElement className="mb-1 h-4 w-32"/>
            <SkeletonElement className="h-8 bg-white md:w-2/3"/>
        </div>
    </div>;

RegistrantForm.Closed = () =>
    <div>
        <h2 className="headline-sm mb-10">Sorry, registration for this event is now closed.</h2>
    </div>
